<template>
  <v-container fluid class="text-center">
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark color="secondary" elevation="2">
        <v-toolbar-title> Empresas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small @click="dialogs.new = true">
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
          Actualizar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          class="mx-2"
          single-line
          hide-details
          maxlength="150"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
        :items-per-page="10"
        item-key="id"
        class="elevation-1"
        dense
      >
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)"
                >mdi-pencil</v-icon
              ></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'sources')"
                >mdi-factory</v-icon
              ></template
            ><span>Fuentes de emisión</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'forms')"
                >mdi-clipboard-text</v-icon
              ></template
            ><span>Asignar formularios</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="getDetailForms(item.idempresa)"
                >mdi-clipboard-text-search</v-icon
              ></template
            ><span>Mostrar formularios</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="view(item, 'compensations')"
                >mdi-cash-plus</v-icon
              ></template
            ><span>Compensaciones</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'users')"
                >mdi-account-multiple</v-icon
              ></template
            ><span>Usuarios asignados</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="getReductionMeasures(item.idempresa)"
                >mdi-handshake</v-icon
              ></template
            ><span>Compromisos de reducción</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="getLogo(item.idempresa)"
                >mdi-image</v-icon
              ></template
            ><span>Logo</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idempresa)"
                >mdi-delete</v-icon
              ></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nueva empresa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.new.razon_social"
                    label="Razón social"
                    :rules="rules.razon_social"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.rfc" label="RFC" :rules="rules.rfc" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.new.direccion"
                    label="Dirección"
                    :rules="rules.direccion"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.new.telefono"
                    label="Teléfono"
                    :rules="rules.telefono"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.email" label="Correo" :rules="rules.email" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.new.no_empleados"
                    label="No. empleados"
                    :rules="rules.no_empleados"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.new.sector_profesional"
                    :items="catalogues.sectores_profesionales"
                    item-text="nombre"
                    item-value="idsector_profesional"
                    label="Sector profesional"
                    :rules="rules.sector_profesional"
                    :disabled="loading"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.new.objetivo"
                    :items="catalogues.objetivos"
                    label="Objetivo"
                    :disabled="loading"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.new.equipos"
                    :items="catalogues.equipos"
                    label="Equipos"
                    :disabled="loading"
                    multiple
                    hide-details
                    required
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" small>
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption"> (+{{ forms.new.equipos.length - 1 }} más) </span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar empresa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.razon_social"
                    label="Razón social"
                    :rules="rules.razon_social"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.rfc" label="RFC" :rules="rules.rfc" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.direccion"
                    label="Dirección"
                    :rules="rules.direccion"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.telefono"
                    label="Teléfono"
                    :rules="rules.telefono"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.email" label="Correo" :rules="rules.email" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.no_empleados"
                    label="No. empleados"
                    :rules="rules.no_empleados"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.update.sector_profesional"
                    :items="catalogues.sectores_profesionales"
                    item-text="nombre"
                    item-value="idsector_profesional"
                    label="Sector profesional"
                    :rules="rules.sector_profesional"
                    :disabled="loading"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.update.objetivo"
                    :items="catalogues.objetivos"
                    label="Objetivo"
                    :disabled="loading"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="forms.update.equipos"
                    :items="catalogues.equipos"
                    label="Equipos"
                    :disabled="loading"
                    multiple
                    hide-details
                    required
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" small>
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption"> (+{{ forms.update.equipos.length - 1 }} más) </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col v-if="forms.update.detalle_extra" cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header> Detalle extra </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr v-for="(value, name) in forms.update.detalle_extra">
                                <td class="font-weight-bold">{{ name.toUpperCase() }}</td>
                                <td style="color: #1565c0">{{ value }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.sources" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Asignar fuentes de emisión</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.update.fuentes_emisiones"
                    :items="catalogues.fuentes_emisiones"
                    item-text="nombre"
                    item-value="idfuente_emision"
                    label="Fuentes de emisión"
                    multiple
                    chips
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.compensations" persistent max-width="800px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Compensaciones</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formCompensation" lazy-validation>
              <v-row>
                <v-col cols="2">
                  <v-menu
                    ref="menuCompensation"
                    v-model="forms.menu"
                    :close-on-content-click="false"
                    :return-value.sync="forms.compensation.periodo"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.compensation.periodo"
                        label="Periodo"
                        v-bind="attrs"
                        v-on="on"
                        class="mt-6"
                        :rules="rules.compensacion.periodo"
                        :disabled="loading"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="forms.compensation.periodo" type="month" locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="blue-grey" @click="forms.menu = false"> Cancelar </v-btn>
                      <v-btn text color="success" @click="$refs.menuCompensation.save(forms.compensation.periodo)"> Aceptar </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="forms.compensation.proyecto"
                    label="Proyecto"
                    class="mt-6"
                    :rules="rules.compensacion.proyecto"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="forms.compensation.codigo"
                    label="Código"
                    class="mt-6"
                    :rules="rules.compensacion.codigo"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="forms.compensation.valor"
                    label="Valor"
                    class="mt-6"
                    :rules="rules.compensacion.valor"
                    :disabled="loading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    fab
                    icon
                    small
                    :disabled="forms.update.compensaciones.length >= 100 || loading"
                    color="primary"
                    elevation="0"
                    class="mt-8"
                    @click="addCompensation()"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <template v-if="forms.update.compensaciones.length">
              <v-divider class="ma-5"></v-divider>
              <v-row justify="center">
                <span class="font-weight-bold">Historial</span>
              </v-row>
            </template>
            <template v-for="(item, idx) in forms.update.compensaciones">
              <v-row :key="idx">
                <v-col cols="2">
                  <v-text-field v-model="item.periodo" label="Periodo" :disabled="loading" readonly required></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model="item.proyecto" label="Proyecto" :disabled="loading" readonly required></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model="item.codigo" label="Código" :disabled="loading" readonly required></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model="item.valor" label="Valor" :disabled="loading" readonly required></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    fab
                    icon
                    small
                    :disabled="loading"
                    color="error"
                    elevation="0"
                    class="mt-3"
                    @click="forms.update.compensaciones.splice(idx, 1)"
                  >
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.commitments" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Compromisos de reducción</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formCommitments" lazy-validation>
              <v-list two-line>
                <v-list-item-group v-model="forms.commitments.selected" active-class="success--text">
                  <template v-for="(item, idx) in forms.commitments.items">
                    <v-list-item :key="item.id" @click="updateReductionMeasures()">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.equipo"></v-list-item-title>
                          <v-list-item-subtitle class="text--primary" v-text="item.energia.join(', ')"></v-list-item-subtitle>
                          <v-list-item-subtitle v-text="item.sugerencia"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text v-text="item.ahorro"></v-list-item-action-text>
                          <v-icon v-if="item.comprometido" color="success">mdi-check-circle</v-icon>
                          <v-icon v-else color="grey lighten-1">mdi-check-circle</v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                    <v-divider v-if="idx < forms.commitments.items.length - 1" :key="'div-' + idx"></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.updateCommitment" persistent max-width="290">
      <v-card v-if="forms.commitments.selected !== null">
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text v-if="!forms.commitments.items[forms.commitments.selected].comprometido"
          >¿Realmente desea establecer el compromiso?</v-card-text
        >
        <v-card-text v-else>¿Realmente desea eliminar el compromiso?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn
            text
            small
            :loading="loading"
            :color="!forms.commitments.items[forms.commitments.selected].comprometido ? 'success' : 'error'"
            @click="updateReductionMeasures()"
          >
            <span v-if="!forms.commitments.items[forms.commitments.selected].comprometido">Aceptar</span>
            <span v-else>Eliminar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.users" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Usuarios asignados</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUsers" lazy-validation>
              <v-row>
                <template v-for="(item, idx) in forms.update.usuarios">
                  <v-col cols="12" :key="idx">
                    <v-text-field
                      :value="
                        (
                          item.detalles_usuarios[0].nombre +
                          ' ' +
                          item.detalles_usuarios[0].apellido_paterno +
                          ' ' +
                          item.detalles_usuarios[0].apellido_materno
                        ).trim() +
                        ' (' +
                        item.email +
                        ')'
                      "
                      readonly
                      required
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.logo" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Logo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="updateLogo()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formLogo" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="forms.logo.file"
                    chips
                    show-size
                    label="Seleccionar imagen..."
                    accept="image/png"
                    :disabled="loading"
                  ></v-file-input>
                </v-col>
                <v-col v-if="forms.logo.image" cols="12">
                  <v-card color="blue-grey">
                    <v-card-text>
                      <v-img :src="forms.logo.image" max-height="500" contain />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.forms" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Asignar formularios</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.update.formularios"
                    :items="catalogues.formularios"
                    item-text="nombre"
                    item-value="idformulario"
                    label="Formularios"
                    multiple
                    chips
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.detailForm" persistent max-width="1000px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Detalle de formularios</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-data-table
              v-model="selectedForm"
              :headers="headersForm"
              :items="itemsForm"
              :loading="loading"
              :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
              :items-per-page="10"
              item-key="id"
              class="elevation-1"
              dense
            >
              <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon
                      class="mr-2"
                      size="22"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loading"
                      @click="downloadAnswersForm(item)"
                      >mdi-download</v-icon
                    ></template
                  ><span>Descargar respuestas</span></v-tooltip
                >
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="resetForm(item)"
                      >mdi-refresh</v-icon
                    ></template
                  ><span>Reiniciar respuestas</span></v-tooltip
                >
              </template>
              <template v-slot:[`item.respondido`]="{ item }">
                <v-icon v-if="item.respondido" color="success" class="mx-1">mdi-check-circle</v-icon>
                <v-icon v-else color="grey" class="mx-1">mdi-check-circle</v-icon>
              </template>
              <template v-slot:[`item.completado`]="{ item }">
                <v-icon v-if="item.completado" color="success" class="mx-1">mdi-check-circle</v-icon>
                <v-icon v-else color="grey" class="mx-1">mdi-check-circle</v-icon>
              </template>
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data> No hay información disponible </template>
              <template v-slot:no-results> No se obtuvieron resultados </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar la empresa?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services';
import writeXlsxFile from 'write-excel-file';

export default {
  name: 'company',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Razón social', align: 'left', sortable: false, value: 'razon_social' },
      { text: 'RFC', align: 'left', sortable: false, value: 'rfc' },
      { text: 'Correo', align: 'left', sortable: false, value: 'email' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' }
    ],
    headersForm: [
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Iniciado', align: 'left', sortable: false, value: 'respondido' },
      { text: 'Completado', align: 'left', sortable: false, value: 'completado' },
      { text: 'Usuario', align: 'left', sortable: false, value: 'usuario' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' }
    ],
    items: [],
    itemsForm: [],
    selected: [],
    selectedForm: [],
    deleted: null,
    menu: false,
    catalogues: {
      sectores_profesionales: [],
      fuentes_emisiones: [],
      formularios: [],
      objetivos: ['Cero emisiones en el 2030', 'Cero emisiones en el 2050', 'Reducción anual del 4.2%'],
      equipos: []
    },
    forms: {
      new: {
        nombre: '',
        razon_social: '',
        rfc: '',
        direccion: '',
        telefono: '',
        email: '',
        no_empleados: '',
        sector_profesional: '',
        objetivo: null,
        equipos: [],
        compensaciones: [],
        compromisos: []
      },
      update: {
        idempresa: '',
        nombre: '',
        razon_social: '',
        rfc: '',
        direccion: '',
        telefono: '',
        email: '',
        no_empleados: '',
        sector_profesional: '',
        objetivo: null,
        equipos: [],
        usuarios: [],
        compensaciones: []
      },
      compensation: {
        periodo: '',
        proyecto: '',
        codigo: '',
        valor: ''
      },
      commitments: {
        company: null,
        selected: null,
        items: []
      },
      logo: {
        empresa: null,
        file: null,
        image: null
      }
    },
    rules: {
      nombre: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 250) || 'El campo excede la longitud máxima'],
      razon_social: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 250) || 'El campo excede la longitud máxima'],
      rfc: [(v) => !!v || 'El campo es requerido', (v) => /^[ñA-Z]{3,4}[0-9]{6}[0-9A-Z]{3}$/.test(v) || 'El campo es inválido'],
      direccion: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 250) || 'El campo excede la longitud máxima'],
      telefono: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 15) || 'El campo excede la longitud máxima'],
      email: [
        (v) => !!v || 'El campo es requerido',
        (v) => (v && v.length <= 320) || 'El campo excede la longitud máxima',
        (v) =>
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'El campo es inválido'
      ],
      no_empleados: [
        (v) => !!v || 'El campo es requerido',
        (v) => (/^\d+$/.test(v) && parseInt(v) >= 1 && parseInt(v) <= 9999) || 'El campo es inválido'
      ],
      sector_profesional: [(v) => !!v || 'El campo es requerido'],
      compensacion: {
        periodo: [(v) => !!v || 'El campo es requerido', (v) => /^\d{4}\-\d{2}$/.test(v) || 'El campo es inválido'],
        proyecto: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 500) || 'El campo excede la longitud máxima'],
        codigo: [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 250) || 'El campo excede la longitud máxima'],
        valor: [
          (v) => !!v || 'El campo es requerido',
          (v) =>
            (/^\d{1,6}(\.\d{1,5})?$/.test(v) && parseFloat(v) >= 0 && parseFloat(parseFloat(v).toFixed(5)) <= 999999.99999) ||
            'El campo es inválido (número decimal)'
        ]
      }
    },
    dialogs: {
      new: false,
      update: false,
      sources: false,
      forms: false,
      detailForm: false,
      compensations: false,
      commitments: false,
      updateCommitment: false,
      users: false,
      logo: false,
      remove: false
    }
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      const sector = await this.axios.get(services.routes.professionalSector);
      if (sector.data.data) {
        this.catalogues.sectores_profesionales = sector.data.data;
      }
      const source = await this.axios.get(services.routes.emissionSource);
      if (source.data.data) {
        this.catalogues.fuentes_emisiones = source.data.data;
      }
      const form = await this.axios.get(services.routes.form);
      if (form.data.data) {
        this.catalogues.formularios = form.data.data;
      }
      const item = await this.axios.get(services.routes.item);
      if (item.data.data) {
        this.catalogues.equipos = item.data.data;
      }
      this.axios
        .get(services.routes.company)
        .then((response) => {
          this.items = response.data.data;
          console.log('ITEMS', this.items);
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          if (key === 'objetivo') {
            this.forms.new[key] = null;
          } else if (key === 'equipos') {
            this.forms.new[key] = [];
          } else {
            this.forms.new[key] = '';
          }
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.$refs.formUpdate.reset();
        for (let key in this.forms.update) {
          if (key === 'objetivo') {
            this.forms.update[key] = null;
          } else if (key === 'equipos') {
            this.forms.update[key] = [];
          } else if (key === 'compensaciones') {
            this.forms.update[key] = [];
          } else {
            this.forms.update[key] = '';
          }
        }
        this.dialogs.update = false;
      } else if (this.dialogs.compensations) {
        this.$refs.formCompensation.reset();
        this.forms.compensation = {
          periodo: '',
          proyecto: '',
          codigo: '',
          valor: ''
        };
        this.dialogs.compensations = false;
      } else if (this.dialogs.updateCommitment) {
        this.forms.commitments.selected = null;
        this.dialogs.updateCommitment = false;
      } else if (this.dialogs.commitments) {
        this.$refs.formCommitments.reset();
        this.forms.commitments = {
          company: null,
          selected: null,
          items: []
        };
        this.dialogs.commitments = false;
      } else if (this.dialogs.logo) {
        this.$refs.formLogo.reset();
        this.forms.logo = {
          empresa: null,
          file: null,
          image: null
        };
        this.dialogs.logo = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      } else if (this.dialogs.detailForm) {
        this.itemsForm = [];
        this.selectedForm = [];
        this.dialogs.detailForm = false;
      }
      this.menu = false;
      this.dialogs.sources = false;
      this.dialogs.forms = false;
      this.dialogs.users = false;
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.company, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = 'Empresa creada exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = 'La información es incorrecta';
      }
    },
    view(data, type) {
      this.forms.update = {
        idempresa: data.idempresa,
        nombre: data.nombre,
        razon_social: data.razon_social,
        rfc: data.rfc,
        direccion: data.direccion,
        telefono: data.telefono,
        email: data.email,
        no_empleados: data.no_empleados,
        sector_profesional: data.sector_profesional ? data.sector_profesional.idsector_profesional : '',
        objetivo: data.objetivo,
        equipos: data.equipos,
        compensaciones: JSON.parse(JSON.stringify(data.compensaciones)),
        detalle_extra: data.detalle_extra
      };
      if (type === 'sources') {
        this.forms.update.fuentes_emisiones = [];
        for (let i = 0; i < data.fuentes_empresas.length; i++) {
          this.forms.update.fuentes_emisiones.push(data.fuentes_empresas[i].fuente_emision.idfuente_emision);
        }
        this.dialogs.sources = true;
      } else if (type === 'forms') {
        this.forms.update.formularios = [];
        for (let i = 0; i < data.formularios_empresas.length; i++) {
          this.forms.update.formularios.push(data.formularios_empresas[i].formulario.idformulario);
        }
        this.dialogs.forms = true;
      } else if (type === 'compensations') {
        this.dialogs.compensations = true;
      } else if (type === 'users') {
        this.forms.update.usuarios = [];
        for (let i = 0; i < data.usuarios_empresas.length; i++) {
          this.forms.update.usuarios.push(data.usuarios_empresas[i].usuario);
        }
        this.dialogs.users = true;
      } else {
        this.dialogs.update = true;
      }
    },
    getLogo(id) {
      this.loading = true;
      this.axios
        .get(services.routes.company + '/logo?id=' + id)
        .then((response) => {
          let data = response.data.data;
          this.forms.logo.empresa = id;
          if (data) {
            data = data.data;
            this.forms.logo.image =
              'data:image/png;base64,' + btoa(new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
          }
          this.dialogs.logo = true;
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateLogo() {
      if (this.forms.logo.file) {
        let fr = new FileReader();
        let file = this.forms.logo.file;
        let size = file.size;
        if (size <= 1000000) {
          fr.onload = () => {
            this.loading = true;
            let data = {
              idempresa: this.forms.logo.empresa,
              logo: fr.result.split(',')[1]
            };
            this.axios
              .put(services.routes.company + '/logo', { data })
              .then((response) => {
                this.cancel();
                this.refresh();
                this.success = true;
                this.msgSuccess = 'Logo actualizado exitosamente';
              })
              .catch((error) => {
                this.error = true;
                this.msgError = error.response.data.message || 'Servicio no disponible';
              })
              .finally(() => {
                this.loading = false;
              });
          };
          fr.onerror = () => {
            this.loading = false;
            this.forms.logo.file = null;
            this.error = true;
            this.msgError = 'El archivo no fue leído correctamente';
          };
          fr.readAsDataURL(file);
        } else {
          this.error = true;
          this.msgError = 'El archivo ha excedido el límite permitido (1 MB)';
        }
      } else {
        this.error = true;
        this.msgError = 'Seleccionar un archivo';
      }
    },
    addCompensation() {
      if (this.$refs.formCompensation.validate()) {
        this.forms.update.compensaciones.push(JSON.parse(JSON.stringify(this.forms.compensation)));
        this.$refs.formCompensation.reset();
        this.forms.compensation = {
          periodo: '',
          codigo: '',
          valor: ''
        };
      } else {
        this.error = true;
        this.msgError = 'La información es incorrecta';
      }
    },
    getReductionMeasures(id) {
      this.loading = true;
      this.axios
        .get(services.routes.company + '/reductionMeasure?id=' + id)
        .then((response) => {
          let data = response.data.data;
          this.forms.commitments.company = id;
          this.forms.commitments.items = data;
          this.dialogs.commitments = true;
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateReductionMeasures() {
      if (!this.dialogs.updateCommitment) {
        this.dialogs.updateCommitment = true;
      } else {
        this.loading = true;
        let comprometido = this.forms.commitments.items[this.forms.commitments.selected].comprometido;
        this.forms.commitments.items[this.forms.commitments.selected].comprometido = !comprometido;
        let data = {
          idempresa: this.forms.commitments.company,
          compromisos: this.forms.commitments.items
        };
        this.axios
          .put(services.routes.company + '/reductionMeasure', { data })
          .then((response) => {
            this.forms.commitments.selected = null;
            this.dialogs.updateCommitment = false;
            this.cancel();
            this.refresh();
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getDetailForms(id) {
      this.loading = true;
      this.axios
        .get(services.routes.company + '/form?id=' + id)
        .then((response) => {
          this.itemsForm = response.data.data;
          this.dialogs.detailForm = true;
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadAnswersForm(item) {
      this.loading = true;
      let params = {
        id: item.id,
        empresa: item.empresa
      };
      this.axios
        .get(services.routes.company + '/form/answer', { params })
        .then((response) => {
          if (response.data.data.length) {
            let data = [];
            let headers = [];
            for (let key in response.data.data[0]) {
              headers.push({
                value: key,
                fontWeight: 'bold'
              });
            }
            data.push(headers);
            for (let i = 0; i < response.data.data.length; i++) {
              let rows = [];
              for (let key in response.data.data[0]) {
                rows.push({
                  type: String,
                  value: response.data.data[i][key]
                });
              }
              data.push(rows);
            }
            writeXlsxFile(data, { fileName: item.nombre + '.xlsx' });
            this.loading = false;
          } else {
            this.error = true;
            this.msgError = 'No hay información disponible';
          }
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm(item) {
      this.loading = true;
      let data = {
        id: item.id,
        empresa: item.empresa
      };
      this.axios
        .put(services.routes.company + '/form/reset', { data })
        .then((response) => {
          this.cancel();
          this.success = true;
          this.msgSuccess = 'Formulario reiniciado exitosamente';
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      if (this.dialogs.compensations) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.company, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = 'Compensaciones actualizadas exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.company, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = 'Empresa actualizada exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = 'La información es incorrecta';
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        let id = this.deleted;
        this.axios
          .delete(services.routes.company, { data: { data: { idempresa: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = 'Empresa eliminada exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    }
  }
};
</script>

<style scoped></style>
